<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>Returns</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
              <v-btn small fab color="info" @click="openCreateReturnDialog" class="ml-2"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row v-if="getGlobalValue('productSerialShowOnReturn')==='true'">
            <v-col>
              <v-text-field class="mb-2" hide-details style="width: 500px;" @keydown.enter="searchSerial" dense outlined :label="`Search ${serialNaming.plural}`" v-model="serialSearch"></v-text-field>
              <v-btn color=info small @click="searchSerial" :disabled="!serialSearch || !serialSearch.trim()">Search</v-btn>
              <span v-if="serialSearchResults && serialSearchResults.length > 0" class="d-flex flex-column">
            <b>Found {{ serialSearchResults.reduce((a,b) => a+b.docs.length, 0) }} {{ serialSearchResults.reduce((a,b) => a+b.docs.length, 0) > 1 ? "documents" : "document" }} from {{ serialSearchResults.length }} {{ serialSearchResults.length > 1 ? "products" : "product" }} containing the term "{{ serialSearch }}"</b>
            <span v-for="(result, index) in serialSearchResults" :key="index">
              <hr class="mt-2">
              <b>Product #{{result.Product.id}} | {{result.Product.name}}</b>
              <span class="d-flex flex-row flex-wrap">
                <v-btn v-for="(doc, d) of result.docs" :key="d" class="mr-2 mt-1" small @click="$router.push(`/returns/view/${doc.returnId}`)">Return #{{ doc.returnId }}</v-btn>
              </span>
            </span>
          </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!loader">
              <v-data-table
                  :headers="headers"
                  :items="allData"
                  :options.sync="options"
                  :server-items-length="pagination.dataCount"
                  :loading="pagination.loading"
                  :items-per-page="15"
                  class="elevation-1"
                  :footer-props="{
                      itemsPerPageOptions: pagination.pageSizeOptions
                    }"
                  @click:row="rowClick"
              >
                <template v-slot:item.customerName="{ item }">
                  <span>{{ item.Customer?item.Customer.name:"NONE" }}</span>
                </template>
                <template v-slot:item.CSRName="{ item }">
                  <span>{{ item.createdBy?lookupUsername(item.createdBy):"None" }}</span>
                </template>
                <template v-slot:item.status="{ item }">

                  <span v-if="item">
                    <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===100" class="v-btn red pa-1" style="font-size: 10px">Draft (Unsaved)</span>
                    <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===3" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===4" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===5" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                    <span v-if="item.status===6" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(item.status)}}</span>
                  </span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{ utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.order="{ item }">
                  <span>{{ item?.Order?.id }}</span>
                </template>
                <template v-slot:item.customer="{ item }">
                  <span>{{ item?.Order?.Customer?.name }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn class="mr-1" v-if="isAllowed('Return', 'r')" fab x-small color="info" @click="rowClick(item)">
                    <v-icon>mdi-file</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createReturnDialog.isOpen"
      width="600"
      fixed
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <b>Confirm Create Return</b>
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="closeCreateReturnDialog">Cancel</v-btn>
          <v-btn color="success" text @click="attemptCreateReturn()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils from  '../../plugins/helpers'
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        max25chars: v => v.length <= 25 || 'Input too long!',
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Date Created', value: 'createdAt' },
          { text: 'Created By', value: 'CSRName' },
          { text: 'Order ID', value: 'order' },
          { text: 'Customer', value: 'customer' },
          { text: 'Status', value: 'status' },
          // { text: 'Actions', value: 'actions' },
        ],
        itemHeaders: [
          { text: 'SKU', value: 'sku' },
          { text: 'Name', value: 'name' },
          { text: 'QTY', value: 'quantity' },
          { text: 'Unit Price', value: 'unitPrice' },
          { text: 'Line Total', value: 'lineTotal' },
        ],
        editMode: false,

        selectedReturn: null,

        serialSearch: '',
        serialSearchResults: [],

        data: {},
        allData: [],
        singular: "return",
        singularLower: "return",
        plural: "returns",
        pluralLower: "returns",

        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 15
        },
        updatingRoute: false,
        init: false,

        createReturnDialog: {
          isOpen: false,
          customerId: ''
        },
        rowClicks: 1,
        lastRowClickId: null,
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "id"]
        this.options.sortDesc = [oldQuery.order || false]
        this.options.search = oldQuery.search || ""
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
      this.init = true;

      this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

      await this.getAllData();
      this.init = false;
    },
    computed: {
      ...mapGetters(['getUsername', 'getEndpoint', 'isAllowed', 'getPerms', 'getId', 'lookupUsername', 'getGlobalValue', 'serialNaming'])
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "id"]
          this.options.sortDesc = [oldQuery.order || false]
          this.options.search = oldQuery.search || ""
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      //used for pagination
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        return oldQuery;
      },
      //used for pagination end
      async rowClick(row){
        await this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      async closeRow(){
        await this.$router.replace({path: `/${this.pluralLower}`, query: null});
        this.selectedReturn = null;
      },
      openReturn(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      openCustomer(row){
        this.$router.push({ path: `/customers/view/${row}`})
      },
      openEmployee(row){
        this.$router.push({ path: `/users/view/${row}`})
      },
      async getAllData(){
        try {
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            search: this.options.search
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data
          if(this.$route.query.return){
            let id = parseInt(this.$route.query.return);
            this.selectedReturn = this.allData.find(x => x.id===id);
            if(!this.selectedReturn) await this.closeRow()
          }

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.replace({path: `/${this.pluralLower}`, query: newQuery});
            }
            else{
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.push({path: `/${this.pluralLower}`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end
        } catch (error) {
          console.error(error);
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      },
      formatPrice(value){
        if(!value) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      openCreateReturnDialog(){
        this.createReturnDialog.isOpen = true
      },
      closeCreateReturnDialog(){
        this.createReturnDialog.isOpen = false
      },
      async attemptCreateReturn(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/returns`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          console.log(res.data)
          await this.$router.push({path: `/returns/view/${res.data.data.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      parseStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      goToReturn(){
        console.log("Hi")
      },
      async searchSerial(){
        try{
          if(!this.serialSearch && !this.serialSearch.trim()) return;

          this.loader = true
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/searchSerial`, {query: this.serialSearch})
          if(res.data.error) throw res.data.error
          this.serialSearchResults = res.data.data
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      clearSerialSearchResults(){
        this.serialSearchResults = []
        this.serialSearch = ''
      },
    }
  }
</script>
